












































































































































































import { listProjects } from "@/api/project-service";
import { getProjectLifecycleStatus, getProjectType } from "@/utils/project-utils";
import Learnlink from "@learnlink/interfaces";
import { computed, defineComponent, PropType, reactive } from "@vue/composition-api";
import CustomerThumbnail from "../customers/CustomerThumbnail.vue";
import EditProject from "@/components/projects/EditProject.vue";
import handleError from "@/helpers/errors";
import headers from "@/helpers/projectListHeaders";
import ProjectEmployeeNote from "@/components/projects/ProjectEmployeeNote.vue";
import SellerThumbnail from "../sellers/SellerThumbnail.vue";

export default defineComponent({
  components: {
    CustomerThumbnail,
    EditProject,
    ProjectEmployeeNote,
    SellerThumbnail,
  },
  props: {
    path: {
      type: String as PropType<string>,
      default: null,
    },
  },
  setup(props) {
    const vm = reactive({
      editFirstLessonDialogVisible: false,
      filter: "",
      headers,
      loaded: false,
      loading: false,
      personaFilter: [] as Learnlink.Type.Persona[],
      personas: ["GUHLG", "SJMHFB", "TJMK", "SFLU", "FJSPM", "IFMÅS", "FFTH", "MMD"],
      projectDialogVisible: false,
      projects: [] as Learnlink.Project.FullViewAdmin[],
      searchQuery: "",
      selectedProject: {} as Learnlink.Project.FullViewAdmin,
    });

    const filteredProjects = computed(() => vm.projects.filter(filterPersona));

    function filterPersona(project: Learnlink.Project.FullViewAdmin): boolean {
      if (vm.personaFilter.length) {
        return vm.personaFilter.includes(project.customer.user.persona);
      }
      else {
        return true;
      }
    }

    function closeDialogs() {
      vm.projectDialogVisible = false;
    }

    function getReadableCategories(project: Learnlink.Project.FullViewAdmin): string {
      return project.readableCategories && project.readableCategories.length > 0 ? project.readableCategories.join(",\n") : "Ingen fag valgt";
    }

    function getStudentName(project: Learnlink.Project.FullViewAdmin): string {
      return project.studentName ? project.studentName : "Ingen navn";
    }

    function getWeekdays(project: Learnlink.Project.FullViewAdmin): string {
      return project.weekdays.length > 0 ? project.weekdays.join(", ") : "Ingen tidspunkt valgt";
    }

    function openProjectDialog(project: Learnlink.Project.FullViewAdmin): void {
      vm.selectedProject = project;
      vm.projectDialogVisible = true;
    }

    async function getProjects(): Promise<void> {
      vm.loading = true;

      try {
        if (vm.searchQuery.includes(" ")) vm.searchQuery = vm.searchQuery.replace(/\s/g, "");
        if (vm.searchQuery.includes("+47")) vm.searchQuery = vm.searchQuery.slice(3);
        vm.projects = [];
        vm.projects = await listProjects(props.path ? props.path : (vm.searchQuery ? ("search/" + vm.searchQuery) : "list"));
        vm.loaded = true;
      }
      catch (e) {
        handleError(e);
      }

      vm.loading = false;
    }

    function projectType(project: Learnlink.Project.FullViewAdmin): string {
      return getProjectType(project);
    }

    return {
      closeDialogs,
      filteredProjects,
      getProjectLifecycleStatus,
      getProjects,
      getReadableCategories,
      getStudentName,
      getWeekdays,
      openProjectDialog,
      projectType,
      vm,
    };
  },
});
