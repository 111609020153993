















import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import { updateWeekdays } from "@/api/project-service";
import { weekdays } from "@/components/projects/ProjectDialog/pickerAlternatives";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";

export default defineComponent({
  name: "ProjectWeekdayPicker",
  props: {
    project: {
      type: Object as PropType<Learnlink.Project.default>,
      required: true
    }
  },
  setup(props) {
    const vm = reactive({
      loading: false,
      selection: [] as Learnlink.Type.Weekday[],
    });

    onMounted(() => {
      vm.selection = props.project.weekdays;
    });

    async function save() {
      vm.loading = true;

      try {
        const updatedProject = await updateWeekdays(props.project.ID, vm.selection);
        props.project.weekdays = updatedProject.weekdays;

        store.dispatch("displayAlert", {
          color: "green",
          message: "Oppdatert",
        });
      }
      catch (e) {
        handleError(e);
      }
      vm.loading = false;
    }

    return {
      save,
      vm,
      weekdays,
    };
  },
});
