import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VAlert,{attrs:{"type":"warning","text":"","shaped":""}},[_vm._v(" Obs! Det sendes ikke ut noen automatiske varsler! ")])],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"UID til ny PL"},model:{value:(_vm.newSellerUID),callback:function ($$v) {_vm.newSellerUID=$$v},expression:"newSellerUID"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VSwitch,{attrs:{"label":"Avslutt nåværende oppdrag"},model:{value:(_vm.closeOldProject),callback:function ($$v) {_vm.closeOldProject=$$v},expression:"closeOldProject"}}),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.newSellerUID.length !== 28 || _vm.loading},on:{"click":_vm.changeSellerForProject}},[_vm._v(" Bytt PL ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }