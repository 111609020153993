interface Header {
  text: string;
  sortable: boolean;
  value: string;
  align?: string;
  width?: string;
}

const headers: Header[] = [
  {
    text: "Oppdrags-ID",
    sortable: true,
    value: "ID",
  },
  {
    text: "Prioritet",
    sortable: true,
    value: "customer.user.priority",
  },
  {
    text: "Notat / Ganger kontaktet",
    align: "center",
    sortable: false,
    value: "employeeNote",
    width: "250px"
  },
  {
    text: "Opprettet",
    sortable: true,
    value: "created",
  },
  {
    text: "Matchet",
    sortable: true,
    value: "closed",
  },
  {
    text: "Godkjent",
    sortable: true,
    value: "approved",
  },
  {
    text: "Kunde",
    align: "center",
    sortable: false,
    value: "customer",
  },
  {
    text: "PL / Pro",
    align: "center",
    sortable: false,
    value: "seller",
  },
  {
    text: "Første time",
    align: "center",
    sortable: true,
    value: "firstLesson",
  },
  {
    text: "Siste time",
    align: "center",
    sortable: true,
    value: "lastLesson",
  },
  {
    text: "Timer registrert (ikke betalt ennå)",
    align: "center",
    sortable: true,
    value: "minutesRegisterred",
  },
  {
    text: "Timer solgt",
    align: "center",
    sortable: true,
    value: "minutesSold",
  },
  {
    text: "Automatisk bonus pr. time",
    align: "center",
    sortable: true,
    value: "hourlyLessonCountBonus",
  },
  {
    text: "Manuell bonus pr. time",
    align: "center",
    sortable: true,
    value: "hourlySellerBonus",
  },
];

export default headers;
