













































































































import PracticalInfo from "@/components/projects/PracticalInfo.vue";
import { defineComponent } from "@vue/composition-api";
import CreateProject from "@/components/projects/CreateProject.vue";
import ProjectList from "@/components/core/ProjectList.vue";

export default defineComponent({
  components: { PracticalInfo, ProjectList, CreateProject },
  name: "Projects",
  metaInfo() {
    return {
      title: "Admin / Oppdrag",
    };
  },
});
