












































































































import { studentBirthYear, genders } from "@/components/projects/ProjectDialog/pickerAlternatives";
import { defineComponent, PropType } from "@vue/composition-api";
import DocumentFieldPicker from "@/components/projects/ProjectDialog/DocumentFieldPicker.vue";
import DocumentPropTextAreaEditor from "@/components/projects/ProjectDialog/DocumentPropTextAreaEditor.vue";
import DocumentPropTextFieldEditor from "@/components/projects/ProjectDialog/DocumentPropTextFieldEditor.vue";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "ProjectInformation",
  components: {
    DocumentFieldPicker,
    DocumentPropTextFieldEditor,
    DocumentPropTextAreaEditor,
  },
  props: {
    project: {
      type: Object as PropType<Learnlink.Project.FullViewAdmin>,
      required: true,
    }
  },
  setup() {
    return {
      studentBirthYear,
      genders,
    };
  },
});
