








































import { getProject } from "@/api/project-service";
import handleError from "@/helpers/errors";
import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "PreviousProjectInformation",
  props: {
    projectID: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      fields: [
        {
          title: "Hva har eleven fått til så langt?",
          prop: "accomplishments",
        },
        {
          title: "Hva burde eleven jobbe videre med?",
          prop: "improvementAreas",
        },
      ],
      loading: true,
      project: {},
    });

    onMounted(async () => {
      try {
        vm.project = await getProject(props.projectID);
      }
      catch (e) {
        handleError(e);
      }
      vm.loading = false;
    });

    return {
      vm,
    };
  },
});
