



























import DocumentPropTextAreaEditor from "@/components/projects/ProjectDialog/DocumentPropTextAreaEditor.vue";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "ProjectEmployeeNote",
  components: { DocumentPropTextAreaEditor },
  props: {
    note: {
      type: String as PropType<string>,
      default: "",
    },
    project: {
      type: Object as PropType<Learnlink.Project.FullViewAdmin>,
      default: () => ({}),
    },
  },
  setup() {
    const editingActive = ref(false);

    return {
      editingActive,
    };
  }
});
