

























import { activeCategories } from "@/helpers/categories";
import CreateDocument from "@/components/CreateDocument.vue";
import Vue from "vue";
import Learnlink from "@learnlink/interfaces";

export default Vue.extend({
  name: "CreateProject",
  components: {
    CreateDocument,
  },
  data() {
    return {
      activeCategoriesArray: [],
      projectFields: [
        {
          title: "buyingFor",
          alternatives: ["child", "self"],
          multiple: false,
          val: null,
        },
        {
          title: "categories",
          alternatives: Object.values(activeCategories).map(c => {
            return {
              text: c.title,
              value: `${c.ID}`,
            };
          }),
          multiple: true,
          val: null,
        },
        {
          title: "challenge",
          alternatives: [],
          multiple: false,
          val: null,
        },
        {
          title: "customerUID",
          alternatives: [],
          multiple: false,
          val: null,
        },
        {
          title: "interests",
          alternatives: [],
          multiple: false,
          val: null,
        },
        {
          title: "location",
          alternatives: ["home", "online", "public"],
          multiple: false,
          val: "online",
        },
        {
          title: "studentName",
          alternatives: [],
          multiple: false,
          val: null,
        },
        {
          title: "weekdays",
          alternatives: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
          multiple: true,
          val: null,
        },
      ] as Learnlink.DocumentField.Create[],
    };
  },
});
