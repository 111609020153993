

















































































import {
  approveProject,
  cancelProject,
  completeProject,
  unApproveProject,
  unCancelProject,
  unCompleteProject,
} from "@/api/project-service";
import handleError from "@/helpers/errors";
import store from "@/store/store";
import Learnlink from "@learnlink/interfaces";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ProjectLifecycleButtons",
  props: {
    project: {
      type: Object as PropType<Learnlink.Project.FullViewAdmin>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async approve(project: Learnlink.Project.FullViewAdmin) {
      if (!project.categories.length) {
        throw new Error("Oppdraget mangler fag!");
      }
      this.loading = true;

      try {
        const updatedProject = await approveProject(project.ID);
        Object.assign(this.project, updatedProject);

        store.dispatch("displayAlert", {
          color: "green",
          message: "Oppdatert",
        });
      }
      catch (e) {
        handleError(e);
      }

      this.loading = false;
    },
    async cancel(projectID: string) {
      this.loading = true;

      try {
        const updatedProject = await cancelProject(projectID);
        Object.assign(this.project, updatedProject);

        store.dispatch("displayAlert", {
          color: "green",
          message: "Oppdatert",
        });
      }
      catch (e) {
        handleError(e);
      }

      this.loading = false;
    },
    async complete(projectID: string) {
      this.loading = true;

      try {
        const updatedProject = await completeProject(projectID);
        Object.assign(this.project, updatedProject);

        store.dispatch("displayAlert", {
          color: "green",
          message: "Oppdatert",
        });
      }
      catch (e) {
        handleError(e);
      }

      this.loading = false;
    },
    async unApprove(projectID: string) {
      this.loading = true;

      try {
        const updatedProject = await unApproveProject(projectID);
        Object.assign(this.project, updatedProject);

        store.dispatch("displayAlert", {
          color: "green",
          message: "Oppdatert",
        });
      }
      catch (e) {
        handleError(e);
      }

      this.loading = false;
    },
    async unCancel(projectID: string) {
      this.loading = true;

      try {
        const updatedProject = await unCancelProject(projectID);
        Object.assign(this.project, updatedProject);

        store.dispatch("displayAlert", {
          color: "green",
          message: "Oppdatert",
        });
      }
      catch (e) {
        handleError(e);
      }

      this.loading = false;
    },
    async unComplete(projectID: string) {
      this.loading = true;

      try {
        const updatedProject = await unCompleteProject(projectID);
        Object.assign(this.project, updatedProject);

        store.dispatch("displayAlert", {
          color: "green",
          message: "Oppdatert",
        });
      }
      catch (e) {
        handleError(e);
      }

      this.loading = false;
    },
  },
});
