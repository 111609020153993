import { VBtn } from 'vuetify/lib/components/VBtn';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[(!_vm.project.sellerUID && !_vm.project.cancelled)?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.project.approved)?_c(VBtn,_vm._g({attrs:{"loading":_vm.loading,"color":"green","dark":""},on:{"click":function($event){return _vm.approve(_vm.project)}}},on),[_vm._v(" Godkjenn oppdraget ")]):(_vm.project.approved)?_c(VBtn,{attrs:{"loading":_vm.loading,"color":"red","dark":""},on:{"click":function($event){return _vm.unApprove(_vm.project.ID)}}},[_vm._v(" Fjern godkjenning ")]):_vm._e()]}}],null,false,3158499578)},[_c('span',[_vm._v("Når oppdraget godkjennes sendes intromail på e-post.")])])],1):_vm._e(),(_vm.project.minutesSold < 240)?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.project.cancelled)?_c(VBtn,_vm._g({attrs:{"loading":_vm.loading,"color":"red","dark":""},on:{"click":function($event){return _vm.cancel(_vm.project.ID)}}},on),[_vm._v(" Kanseller oppdraget ")]):(_vm.project.cancelled)?_c(VBtn,{attrs:{"loading":_vm.loading,"color":"red","dark":""},on:{"click":function($event){return _vm.unCancel(_vm.project.ID)}}},[_vm._v(" Angre kansellering ")]):_vm._e()]}}],null,false,3972669691)},[_c('span',[_vm._v("Når oppdraget kanselleres sendes ingenting.")])])],1):(_vm.project.minutesSold >= 240)?_c('div',[(!_vm.project.completed && !_vm.project.cancelled)?_c(VBtn,_vm._g({attrs:{"loading":_vm.loading,"color":"warning","dark":""},on:{"click":function($event){return _vm.complete(_vm.project.ID)}}},_vm.on),[_vm._v(" Fullfør oppdraget ")]):(_vm.project.completed && !_vm.project.cancelled)?_c(VBtn,_vm._g({attrs:{"loading":_vm.loading,"color":"red","dark":""},on:{"click":function($event){return _vm.unComplete(_vm.project.ID)}}},_vm.on),[_vm._v(" Angre fullføring ")]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }