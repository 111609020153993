export const activeCategories = {
  72: {
    ID: "72",
    category: 1,
    active: 1,
    level: 1,
    parent: "63",
    title: "Naturfag 1. - 7. trinn",
    type: 0,
  },
  73: {
    ID: "73",
    category: 1,
    active: 1,
    level: 1,
    parent: "79",
    title: "Norsk 1. - 7. trinn",
    type: 0,
  },
  74: {
    ID: "74",
    category: 1,
    active: 1,
    level: 1,
    parent: "75",
    title: "Engelsk 1. - 7. trinn",
    type: 0,
  },
  124: {
    ID: "124",
    category: 1,
    active: 1,
    level: 1,
    parent: "63",
    title: "Matte 1. - 7. trinn",
    type: 0,
  },
  125: {
    ID: "125",
    category: 1,
    active: 1,
    level: 2,
    parent: "63",
    title: "Matte 8. - 10. trinn",
    type: 0,
  },
  132: {
    ID: "132",
    category: 1,
    active: 0,
    level: 1,
    parent: "79",
    title: "Generell leksehjelp",
    type: 0,
  },
  133: {
    ID: "133",
    category: 1,
    active: 1,
    level: 2,
    parent: "63",
    title: "Naturfag 8. - 10. trinn",
    type: 0,
  },
  134: {
    ID: "134",
    category: 1,
    active: 1,
    level: 2,
    parent: "75",
    title: "Engelsk 8. - 10. trinn",
    type: 0,
  },
  135: {
    ID: "135",
    category: 1,
    active: 1,
    level: 2,
    parent: "79",
    title: "Norsk 8. - 10. trinn",
    type: 0,
  },
  137: {
    ID: "137",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte 1P",
    type: 0,
  },
  138: {
    ID: "138",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte 2P",
    type: 0,
  },
  139: {
    ID: "139",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte R1",
    type: 0,
  },
  140: {
    ID: "140",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte R2",
    type: 0,
  },
  141: {
    ID: "141",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte S1",
    type: 0,
  },
  142: {
    ID: "142",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte S2",
    type: 0,
  },
  143: {
    ID: "143",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte T1",
    type: 0,
  },
  145: {
    ID: "145",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Matte (Vet ikke hvilket fag) VGS",
    type: 0,
  },
  151: {
    ID: "151",
    category: 1,
    active: 1,
    level: 3,
    parent: "79",
    title: "Norsk VGS",
    type: 0,
  },
  152: {
    ID: "152",
    category: 1,
    active: 1,
    level: 3,
    parent: "75",
    title: "Engelsk VGS",
    type: 0,
  },
  153: {
    ID: "153",
    category: 1,
    active: 1,
    level: 3,
    parent: "63",
    title: "Naturfag VGS",
    type: 0,
  },
  159: {
    ID: "159",
    category: 1,
    active: 1,
    level: 1,
    parent: "92",
    title: "Scratch-kurs 1–1",
    type: 0,
  },
  160: {
    ID: "160",
    category: 1,
    active: 1,
    level: 1,
    parent: "92",
    title: "Kodekurs",
    type: 0,
  },
  161: {
    ID: "161",
    category: 1,
    active: 1,
    level: 1,
    parent: "92",
    title: "Koding 1-1",
    type: 0,
  },
};
