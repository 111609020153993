

























import { closeProject } from "@/api/project-service";
import handleError, { sanitizeError } from "@/helpers/errors";
import store from "@/store/store";
import Vue, { PropType } from "vue";
import Learnlink from "@learnlink/interfaces";

export default Vue.extend({
  name: "MatchProjectWithSeller",
  props: {
    project: {
      type: Object as PropType<Learnlink.Project.FullViewAdmin>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      sellerUID: "",
      showNotifications: false,
    };
  },
  mounted() {
    this.$root.$on("setSellerUID", (uid: string) => {
      this.sellerUID = uid;
    });
  },
  methods: {
    async matchProjectWithSeller() {
      this.loading = true;
      try {
        const updatedProject = await closeProject(this.project.ID, this.sellerUID);
        Object.assign(this.project, updatedProject);

        store.dispatch("displayAlert", {
          color: "green",
          message: "Matchet!",
        });
      }
      catch (e) {
        handleError(e);
        store.dispatch("displayAlert", {
          color: "red",
          message: sanitizeError(e as Error).message,
        });
      }
      this.loading = false;
    },
  },
});
