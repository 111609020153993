




























































































































import { TargetGroup } from "@/helpers/projectUtils";
import { getProjectType } from "@/utils/project-utils";
import Learnlink from "@learnlink/interfaces";
import ChangeSeller from "./ProjectDialog/ChangeSeller.vue";
import MatchProjectWithSeller from "./MatchProjectWithSeller.vue";
import PreviousProjectInformation from "@/components/projects/ProjectDialog/PreviousProjectInformation.vue";
import ProjectCategoryPicker from "@/components/core/ProjectCategoryPicker.vue";
import ProjectInformation from "@/components/projects/ProjectDialog/ProjectInformation.vue";
import ProjectLifecycleButtons from "@/components/projects/ProjectDialog/ProjectLifecycleButtons.vue";
import ProjectWeekdayPicker from "./ProjectDialog/ProjectWeekdayPicker.vue";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "EditProject",
  components: {
    ChangeSeller,
    MatchProjectWithSeller,
    PreviousProjectInformation,
    ProjectCategoryPicker,
    ProjectInformation,
    ProjectLifecycleButtons,
    ProjectWeekdayPicker,
  },
  props: {
    project: {
      type: Object as PropType<Learnlink.Project.FullViewAdmin>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      propValue: this.value,
      TargetGroup,
    };
  },
  computed: {
    projectType(): string {
      return getProjectType(this.project);
    },
  },
  watch: {
    propValue: function(): void {
      this.$emit("input", this.propValue);
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    success() {
      this.$emit("success");
      this.$emit("close");
    }
  }
});
