

































import { changeSeller } from "@/api/project-service";
import handleError from "@/helpers/errors";
import store from "@/store/store";
import Vue, { PropType } from "vue";
import Learnlink from "@learnlink/interfaces";

export default Vue.extend({
  name: "ChangeSeller",
  props: {
    project: {
      type: Object as PropType<Learnlink.Project.FullViewAdmin>,
      required: true,
    },
  },
  data() {
    return {
      closeOldProject: true,
      loading: false,
      newSellerUID: "",
    };
  },
  methods: {
    async changeSellerForProject() {
      this.loading = true;
      try {
        await changeSeller(this.project.ID, this.newSellerUID, this.closeOldProject);
        store.dispatch("displayAlert", {
          color: "green",
          message: "PL byttet",
        });
        this.$emit("success");
      }
      catch (e) {
        handleError(e);
      }
      this.loading = false;
    },
  },
});
