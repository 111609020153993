

















import { activeCategories } from "@/helpers/categories";
import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import { updateCategories } from "@/api/project-service";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";

export default defineComponent({
  name: "ProjectCategoryPicker",
  props: {
    project: {
      type: Object as PropType<Learnlink.Project.default>,
      required: true
    }
  },
  setup(props) {
    const vm = reactive({
      loading: false,
      selection: [] as string[],
    });

    onMounted(() => {
      vm.selection = props.project.categories;
    });

    async function save() {
      vm.loading = true;

      try {
        const updatedProject = await updateCategories(props.project.ID, vm.selection);
        props.project.categories = updatedProject.categories;

        store.dispatch("displayAlert", {
          color: "green",
          message: "Oppdatert",
        });
      }
      catch (e) {
        handleError(e);
      }
      vm.loading = false;
    }

    return {
      activeCategories,
      save,
      vm
    };
  },
});
