





























import { getPracticalInfo, updatePracticalInfo } from "@/api/project-service";
import store from "@/store/store";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "PracticalInfo",
  setup() {
    const vm = reactive({
      url: "",
      loading: false,
    });

    onMounted(async () => {
      vm.loading = true;
      vm.url = await getPracticalInfo();
      vm.loading = false;
    });

    async function update(): Promise<void> {
      vm.loading = true;
      try {
        await updatePracticalInfo(vm.url);
        await store.dispatch("displayAlert", {
          color: "green",
          message: "Suksess!",
        });
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Noe gikk galt!",
        });
      }
      vm.loading = false;
    }

    return {
      update,
      vm,
    };
  },
});
