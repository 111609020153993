import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.path)?_c(VToolbar,{attrs:{"color":"primary","dark":"","height":"80"}},[_c(VSpacer),_c(VTextField,{staticClass:"mr-2",attrs:{"label":"Søk etter fornavn, etternavn, epost, telefonnummer eller UID","hide-details":"","outlined":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getProjects.apply(null, arguments)}},model:{value:(_vm.vm.searchQuery),callback:function ($$v) {_vm.$set(_vm.vm, "searchQuery", $$v)},expression:"vm.searchQuery"}}),_c(VBtn,{attrs:{"loading":_vm.vm.loading,"outlined":""},on:{"click":_vm.getProjects}},[_vm._v(" Søk ")]),_c(VSpacer)],1):_vm._e(),_c(VToolbar,{attrs:{"flat":""}},[_vm._l((_vm.vm.personas),function(persona){return _c(VCheckbox,{key:persona,staticClass:"ml-2",attrs:{"label":persona,"value":persona,"hide-details":""},model:{value:(_vm.vm.personaFilter),callback:function ($$v) {_vm.$set(_vm.vm, "personaFilter", $$v)},expression:"vm.personaFilter"}})}),_c(VSpacer),_c(VBtn,{attrs:{"loading":_vm.vm.loading,"outlined":""},on:{"click":function($event){return _vm.getProjects()}}},[_c(VIcon,[_vm._v(" mdi-refresh ")])],1)],2),_c(VDataTable,{attrs:{"headers":_vm.vm.headers,"items":_vm.filteredProjects,"loading":_vm.vm.loading,"search":_vm.vm.filter,"loading-text":"Laster inn oppdrag...","no-data-text":_vm.vm.loaded ? 'Ingen oppdrag funnet.' : 'Søk for å laste inn oppdrag.',"multi-sort":""},scopedSlots:_vm._u([{key:"item.ID",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"color":"primary","small":"","disabled":_vm.vm.loading},on:{"click":function($event){return _vm.openProjectDialog(item)}}},[_vm._v(" Åpne oppdrag ")]),_c('span',[_vm._v(_vm._s(item.ID))]),_c('br'),_c('span',[_c('strong',[_vm._v("Livssyklus:")]),_vm._v(" "+_vm._s(_vm.getProjectLifecycleStatus(item)))]),_c('br'),_c('span',[_c('strong',[_vm._v("Type:")]),_vm._v(" "+_vm._s(_vm.projectType(item)))]),_c('br'),_c('span',[_c('strong',[_vm._v("Kanal:")]),_vm._v(" "+_vm._s(item.channel))]),_c('br'),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v(" Elev: ")]),_vm._v(" "+_vm._s(_vm.getStudentName(item))+" ")]),_c('span',[_c('strong',[_vm._v("Fag:")]),_vm._v(" "+_vm._s(_vm.getReadableCategories(item)))])]}},{key:"item.employeeNote",fn:function(ref){
var item = ref.item;
return [_c(VContainer,{staticClass:"text-center"},[_c('project-employee-note',{attrs:{"project":item}})],1)]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment.unix(item.created).format("LLLL"))+" ")]}},{key:"item.closed",fn:function(ref){
var item = ref.item;
return [(item.closed)?_c('span',[_vm._v(_vm._s(_vm.$moment.unix(item.closed).format("LLLL")))]):_vm._e()]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [(item.approved === 1)?_c('span',[_vm._v("Ja")]):(item.approved)?_c('span',[_vm._v(_vm._s(_vm.$moment.unix(item.approved).format("LLLL")))]):_vm._e()]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(item.customer)?_c('customer-thumbnail',{staticStyle:{"width":"350px"},attrs:{"customer":item.customer}}):_vm._e()]}},{key:"item.seller",fn:function(ref){
var item = ref.item;
return [(item.seller && item.sellerUID && item.sellerUID.length > 0)?_c('div',[_c('seller-thumbnail',{staticStyle:{"width":"400px"},attrs:{"seller":item.seller}})],1):_vm._e()]}},{key:"item.readableCategories",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"150px"}},[_c('strong',[_vm._v("Fag:")]),_c('br'),_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(_vm._s(_vm.getReadableCategories(item)))]),_c('br'),_c('br'),_c('strong',[_vm._v("Åpen for undervisning:")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.getWeekdays(item)))])])]}},{key:"item.firstLesson",fn:function(ref){
var item = ref.item;
return [(item.firstLesson)?_c('span',[_vm._v(_vm._s(_vm.$moment.unix(item.firstLesson.startTime).format("LLLL")))]):_vm._e()]}},{key:"item.lastLesson",fn:function(ref){
var item = ref.item;
return [(item.lastLesson)?_c('span',[_vm._v(_vm._s(_vm.$moment.unix(item.lastLesson.startTime).format("LLLL")))]):_vm._e()]}},{key:"item.minutesRegisterred",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.round(item.minutesRegisterred / 60))+" ")]}},{key:"item.minutesSold",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.round(item.minutesSold / 60))+" ")]}},{key:"item.hourlyLessonCountBonus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hourlyLessonCountBonus / 100)+" ")]}},{key:"item.hourlySellerBonus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hourlySellerBonus / 100)+" ")]}}])}),(_vm.vm.projectDialogVisible)?_c('edit-project',{key:_vm.vm.selectedProject.ID,attrs:{"project":_vm.vm.selectedProject},on:{"close":function($event){return _vm.closeDialogs()},"success":_vm.getProjects},model:{value:(_vm.vm.projectDialogVisible),callback:function ($$v) {_vm.$set(_vm.vm, "projectDialogVisible", $$v)},expression:"vm.projectDialogVisible"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }